<template>
  <div>
    <!--    <router-view v-show="$route.matched.length==3"></router-view>-->
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='box-enter'>
          <div>
            <el-form size='mini' :inline='true'>
              <el-form-item label=''>
                <el-date-picker
                  style='width: 240px'
                  v-model='searchCondition.rangYm'
                  type='monthrange'
                  unlink-panels
                  range-separator='至'
                  start-placeholder='开始月份'
                  end-placeholder='结束月份'
                  value-format='yyyy-MM'
                  format='yyyy年MM月'
                  :picker-options='pickerOptions'
                  @change='changeMonths'>
                </el-date-picker>
              </el-form-item>
              <el-form-item label=''>
                <artist-search-custom :type='``' @handleSelect='selectArtist' :show-first-group='true'
                                      style='width: 100%' />
              </el-form-item>

            </el-form>
          </div>
          <el-row :gutter='1'>
            <el-col :span='24'>
              <el-tabs type='border-card' class='platforms-tab' :stretch='true' :tab-position='tabPosition'
                       style='height: auto;'
                       v-model='searchCondition.platform_code'
                       @tab-click='handleClick' v-if='artistPlatforms.length>0'>
                <el-tab-pane :label='platform.name' :name='`${platform.code}`'
                             v-for='(platform,indx) in artistPlatforms'
                             :key='`${platform.code}_${indx}`'>
                <span slot='label'>
                  <div style='display: block;height: auto'>
                      <el-avatar shape='square' :size='30' :src='platform.logo_url' @error='true'>
                                  <img src='https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png' />
                      </el-avatar>
                    <br />
                    <el-tooltip class='item' effect='dark' :content='`${ platform.name}`' placement='right'>
                        <span class='platform_name'>{{
                            platform.name && platform.name.length > 2 ? platform.name.substring(0, 2) : platform.name
                          }}</span>
                    </el-tooltip>
                  </div>
                </span>
                  <div>
                    <live-data-record :col-set='colsSet' :ref='`liveDataRecord_${platform.code}`'
                                      v-if='searchCondition.platform_code=== platform.code'
                                      :searchCondition='searchCondition'></live-data-record>
                    <br />
                  </div>
                </el-tab-pane>

              </el-tabs>
            </el-col>
          </el-row>
        </div>
        <br />
        <el-row v-if="userPermissions.indexOf('live_data_log')>-1">
          <el-col :span='24'>
            <el-divider>
              <el-link @click='showLog=!showLog'>
                操作日志<span :class='`${!showLog?"el-icon-arrow-down":"el-icon-arrow-up"}`'></span></el-link>
            </el-divider>
            <live-info-log v-if='showLog' />
          </el-col>
        </el-row>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import LiveDataRecord from '@/pages/live_data/LiveDataRecord'
import LiveInfoLog from '@/pages/live_data/LiveInfoLog'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'

export default {
  name: 'LiveDataEnter',
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    LiveDataRecord,
    ArtistSearchCustom,
    LiveInfoLog
  },
  data() {
    return {
      groups: [],//当前页面展示的分组列表
      artists: [],//可选红人列表
      tabPosition: 'left',
      loadingStatus: false,
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15
      },
      // 分页的offset,序号列使用
      offset: 0,
      columns: [],
      platforms: [],
      artistPlatforms: [],
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        width_height: '480px',
        show_footer: false
      },
      searchCondition: {
        group_id: null,
        artist_id: null,
        nickname: null,
        ym: null,
        platform_code: null,
        platform_name: '',
        rangYm: [],
        time_value: []
      },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 2)
            picker.$emit('pick', [start, end])
          }
        },
          {
            text: '最近六个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setMonth(start.getMonth() - 5)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      colsSet: {},
      showLog: false
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  async created() {
    // 平台
    await this.getPageSettings()
    // 我的分组-》个人配置
    //列设置(区分平台)
    await this.getColSet()
  },
  methods: {
    async getColSet() {
      let { colsSet } = await this.$api.getLiveInfoColSet()
      let { custom } = colsSet
      if (custom) {
        custom.forEach((item) => {
          if (item.value === 'commission_estimate' || item.value === 'pit_fee') {
            item.required = false
          }
        })
      }
      this.colsSet = colsSet

    },
    defaultMonth() {
      //默认近6个月
      let currentDate = new Date()
      let currentMonth = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1)
      const start = new Date()
      start.setMonth(start.getMonth() - 5)
      let lastMonth = start.getFullYear() + '-' + (start.getMonth() + 1)
      this.searchCondition.rangYm = [lastMonth, currentMonth]

      // this.searchCondition.time_value = [lastMonth + '-01', currentMonth + '-' + daycount]
    },
    getCurrentMonth() {
      let currentDate = new Date()
      this.searchCondition.ym = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1)
      this.calcTimeRange()
    },
    //计算该红人拥有的平台
    async calcArtistPlatforms() {
      this.searchCondition.platform_code = null
      this.searchCondition.platform_name = null
      this.artistPlatforms = []
      if (this.searchCondition.artist_id) {
        let { list } = await this.$api.getArtistOwnedPlatforms(this.searchCondition.artist_id)
        this.artistPlatforms = list
        if (list[0]) {
          let firstPlatform = list[0]
          this.searchCondition.platform_code = firstPlatform.code
          this.searchCondition.platform_name = firstPlatform.name
          this.handleClick({ name: firstPlatform.code, label: firstPlatform.name })
        }
      }

    },
    async selectArtist(selectInfo) {
      // console.log('si', selectInfo)
      this.searchCondition = { ...this.searchCondition, ...selectInfo }
      await this.calcArtistPlatforms()
      // 筛选
      await this.showRecords()
    },
    calcTimeRange() {
      if (this.searchCondition.ym) {
        let ym = this.searchCondition.ym
        let ymArr = ym.split('-')
        let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0)
        let daycount = day.getDate()//最后一天
        this.searchCondition.time_value = [ym + '-01', ym + '-' + daycount]
      } else {
        this.searchCondition.time_value = []
      }
    },
    changeMonths() {
      if (this.searchCondition.rangYm) {
        this.searchCondition.ym = null
        let beginMonth = this.searchCondition.rangYm[0]
        let endMonth = this.searchCondition.rangYm[1]
        let ymArr = endMonth.split('-')
        let day = new Date(parseInt(ymArr[0]), parseInt(ymArr[1]), 0)
        let daycount = day.getDate()//最后一天
        this.searchCondition.time_value = [beginMonth + '-01', endMonth + '-' + daycount]
      } else {
        this.searchCondition.time_value = []
      }
      this.showRecords()
    },
    showRecords() {
      // 筛选
      this.$nextTick(() => {
        let ref = `liveDataRecord_${this.searchCondition.platform_code}`
        if (this.$refs[ref]) {
          this.$refs[ref][0].initData(this.searchCondition)
        }
      })
    },
    async getPageSettings() {
      //页面配置-依次加载 当前月份-》平台 我的分组-》红人
      // await this.getCurrentMonth()
      await this.defaultMonth()
    },
    errorHandler() {
      return true
    },
    handleClick() {
      // this.tabName = tab.name
      // console.log('tab', tab.name, event.type);
      this.showRecords()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      if (this.searchCondition.nickname) {
        condition.nickname = this.searchCondition.nickname
      }
      if (this.searchCondition.sign_status) {
        condition.sign_status = this.searchCondition.sign_status
      }

      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }

      return condition
    }
  }
}
</script>

<style scoped>
.box-enter {
  border: #f2f2f2 1px solid;
  padding: 8px 5px;
  min-height: 100vh;
  border-radius: 10px;
}

.searchCondition {
  margin-bottom: 10px;
}
</style>
<style>

.platforms-tab .el-tabs__item {
  display: block;
  margin: 1px 1px;
  line-height: 25px;
  font-size: 1rem;
  /*word-wrap: break-word;*/
  writing-mode: horizontal-tb;
  height: 80px;
  padding-left: 10px;
  padding-top: 10px;
  overflow-x: hidden;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #1890ff;
  background-color: #FFFFFF;
  border-right-color: #DCDFE6;
  border-left-color: #DCDFE6;
  font-weight: 600;
  font-size: 14px;
}

.el-select > .el-input {
  width: 200px !important;
}

.el-tabs--border-card > div.el-tabs__content {
  padding: 5px 1px !important;
}
</style>

