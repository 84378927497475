<template>
  <div>
    <div class='table-search'>
      <el-form :inline='true' size='mini'>
        <el-form-item label='数据ID：'>
          <el-input v-model='searchCondition.info_id' placeholder='数据ID（精确匹配）' clearable />
        </el-form-item>
        <el-form-item label='关键字：'>
          <el-input v-model='searchCondition.keyword' placeholder='关键字（模糊匹配）' clearable />
        </el-form-item>
        <el-form-item>
          <el-button :loading='loadingStatus' :disabled='loadingStatus' type='primary' @click='handleQuery'><i class='el-icon-search'></i>搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class='default-table' v-loading='loadingStatus'
         element-loading-text='加载中……'
         element-loading-spinner='el-icon-loading'>
      <el-table :data='logList' border>
        <el-table-column label='序号' type='index' width='100' align='center'></el-table-column>
        <el-table-column label='日志ID' prop='id' width='100' align='center'></el-table-column>
        <el-table-column label='直播数据ID' prop='info_id' width='100' align='center'></el-table-column>
        <el-table-column label='操作内容' prop='msg' min-width='400'></el-table-column>
      </el-table>
      <div>
        <m-pagination :total.sync='pagingData.total' :page.sync='pagingData.current_page'
                      :limit.sync='pagingData.page_size' @pagination='getLogList'></m-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ApeTable from '@/components/ApeTable'

export default {
  name: 'LiveInfoLog',
  components: {
    ApeTable
  },
  props: {
    searchCondition: {
      type: Object,
      default: () => {
        return {
          info_id: null,
          keyword: null
        }
      }
    }
  },
  data() {

    return {
      loadingStatus: false,
      activeNames: [],
      // 分页信息
      pagingData: {
        page_size: 10,
        current_page: 1,
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      // 分页的offset,序号列使用
      offset: 0,
      logList: [],
      columns: [
        {
          title: '操作时间',
          value: 'created_at',
          width: 160,
          align: 'center'
        },
        {
          title: '日志内容',
          value: 'msg',
          width: 800
        },
        {
          title: '数据ID',
          value: 'info_id',
          width: 100,
          align: 'center'
        }
      ]
    }
  },
  methods: {
    handleQuery() {
      this.pagingData.current_page = 1
      this.getLogList()
    },
    search() {
      this.getLogList()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.info_id) {
        condition.info_id = this.searchCondition.info_id
      }
      if (this.searchCondition.keyword) {
        condition.keyword = this.searchCondition.keyword
      }

      return condition
    },
    async getLogList() {
      this.loadingStatus = true
      this.logList = []
      let pagingInfo = { page_size: this.pagingData.page_size, current_page: this.pagingData.current_page }
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, pagingInfo)
      let { list, pages } = await this.$api.getLiveInfoLog(searchCondition)
      this.logList = list || []

      this.pagingData.total = pages.total
      this.pagingData.current_page = pages.current_page
      this.pagingData.page_size = pages.page_size
      this.offset = pages.offset
      this.loadingStatus = false

    }

  }
}
</script>

<style scoped>

</style>
